import React, { createContext, PropsWithChildren, useMemo } from 'react'

import useMessageFeatureList from '@/hooks/useMessageFeatureList'
import useOtherFeaturesList from '@/hooks/useOtherFeaturesList'

type Lists = {
  messageTypes: (Echia.MessageType & { packages: Echia.Package[] })[]
  packages: (Echia.Package & { messageTypes: Echia.MessageType[] })[]
  packageMessageTypes: { package: Echia.Package, messageType: Echia.MessageType }[]
}

type ContextType = [
  Echia.MessageFeature[] | undefined,
  Echia.OtherFeature[] | undefined,
  boolean,
  Partial<Lists>,
]

const defaultValue: ContextType = [undefined, undefined, true, {}]

export const FeaturesContext = createContext<ContextType>(defaultValue)

export const FeaturesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [messageFeatures, isMessageFeaturesLoading] = useMessageFeatureList()
  const [otherFeatures, isOtherFeaturesLoading] = useOtherFeaturesList()

  const lists = useMemo(() => {
    if (!messageFeatures) return {}

    // This is basically just the message features represented as { package: Echia.Package, messageType: Echia.MessageType }
    const packageMessageTypes: { package: Echia.Package, messageType: Echia.MessageType }[] = Object.values(
      messageFeatures?.reduce((acc, cur) => {
        if (acc[`${cur.packageId}-${cur.messageTypeId}`]) return acc
        return {
          ...acc,
          [`${cur.packageId}-${cur.messageTypeId}`]: {
            package: {
              name: cur.package,
              packageId: cur.packageId,
            },
            messageType: {
              messageTypeId: cur.messageTypeId,
              name: cur.messageType,
              isIncoming: cur.messageTypeIsIncoming,
            },
          },
        }
      }, {} as Record<string, { package: Echia.Package, messageType: Echia.MessageType}>)
    )

    const messageTypes = packageMessageTypes
      .map(pmt => pmt.messageType)
      .filter(({ messageTypeId }, index, array) => array.findIndex(entry => entry.messageTypeId === messageTypeId) === index)
      // Apply package list
      .map(messageType => ({
        ...messageType,
        packages: packageMessageTypes
          .filter(pmt => pmt.messageType.messageTypeId === messageType.messageTypeId)
          .map(pmt => pmt.package),
      }))

    const packages = packageMessageTypes
      .map(pmt => pmt.package)
      .filter(({ packageId }, index, array) => array.findIndex(entry => entry.packageId === packageId) === index)
      // Apply message types list
      .map(packageObject => ({
        ...packageObject,
        messageTypes: packageMessageTypes
          .filter(pmt => pmt.package.packageId === packageObject.packageId)
          .map(pmt => pmt.messageType),
      }))

    const lists: Lists = {
      // Also apply sublists here
      messageTypes,
      packages,
      packageMessageTypes,
    }
    return lists
  }, [messageFeatures])

  return <FeaturesContext.Provider value={[
    messageFeatures,
    otherFeatures,
    isMessageFeaturesLoading || isOtherFeaturesLoading,
    lists,
  ]} >
    {children}
  </FeaturesContext.Provider>
}
