import React from 'react'
import classnames from 'classnames'
import './Loading.scss'

// These are the types for a 'div'
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Loading = ({
  ...implicitProps
}: Props) => {
  return <div
    {...implicitProps}
    className={classnames('Loading', implicitProps.className)}
  >
    <div />
  </div>
}

export default Loading
