import React, { createContext, PropsWithChildren, useCallback, useState } from 'react'

import NotificationPanel from '@/components/notifications/NotificationPanel'

interface NotificationContextType {
  addNotification: (newNotification: Dashboard.Notification) => void
}

const NotificationContext = createContext<NotificationContextType>({
  addNotification: newNotification => {
    alert(
      `${newNotification.type}: ${newNotification.content}

This is the default notification display, which means something has gone very wrong.`,
    )
  },
})

const NotificationContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [notifications, setNotifications] = useState<Dashboard.Notification[]>([])
  const addNotification = useCallback((newNotification: Dashboard.Notification) => {
    setNotifications(notifications => [
      ...notifications,
      newNotification,
    ])
  }, [setNotifications])
  const removeNotification = useCallback((index: number) => {
    setNotifications(notifications => [
      ...notifications.slice(0, index),
      ...notifications.slice(index + 1),
    ])
  }, [setNotifications])
  const removeAllNotifications = useCallback(() => {
    setNotifications([])
  }, [setNotifications])
  return <NotificationContext.Provider value={{ addNotification }}>
    {children}
    <NotificationPanel {...{ notifications, removeNotification, removeAllNotifications }} />
  </NotificationContext.Provider>
}

export default NotificationContext
export { NotificationContextProvider }
