import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as zod from 'zod'

import classnames from 'classnames'

import useAxios from '@/hooks/useAxios'
import useQueryParams from '@/hooks/useQueryParams'

import Button from '../Button'

import './ErrorPage.scss'

type ErrorDetail = {
  mainHeader: string
  subHeader: string
  message: string
}

const errorDetailOptions: Record<string, ErrorDetail | undefined> = {
  '/NotFound': {
    mainHeader: '404',
    subHeader: 'Not Found',
    message: 'We couldn\'t find the page you\'re looking for. Oops.',
  },
  '/ServiceUnavailable': {
    mainHeader: '503',
    subHeader: 'Service Unavailable',
    message: 'The dashboard is currently down, probably because we\'re doing an update. Try again later.',
  },
}

const ErrorPage = () => {
  const axios = useAxios(false)
  const history = useHistory()
  const [params] = useQueryParams({
    redirect: zod.string(),
  })

  const errorDetail = errorDetailOptions[history.location.pathname] ?? {
    mainHeader: 'Oops.',
    subHeader: 'An unknown error occured',
    message: 'We\'re not sure what happened here, you should probably contact support.',
  }

  // If a 5XX level error page, try a request. If it doesn't 503, go back to
  // redirect or home.
  useEffect(() => {
    if (history.location.pathname === '/ServiceUnavailable') {
      axios.get('/')
        .then(() => {
          history.push(decodeURIComponent(params.redirect ?? '/'))
        })
        .catch(error => {
          if (!error.response) return

          if (error.response.status !== 503) {
            history.push(decodeURIComponent(params.redirect ?? '/'))
          }
        })
    }
  }, [axios, history, params, history.location.pathname])

  return <div className="ErrorPage">
    <div>
      <h1>{errorDetail.mainHeader}</h1>
      <h2>{errorDetail.subHeader}</h2>
      <p>{errorDetail.message}</p>

      <Button color="blue" onClick={() => history.push('/')} >
        <strong>Go to message list</strong>
      </Button>

    </div>
    <small
      className={classnames({
        versionPresent: !!process.env.REACT_APP_VERSION,
      })}
    >
      If you need technical support, please contact us.
    </small>
  </div>
}

export default ErrorPage
