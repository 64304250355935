import React, { useContext, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom'

import './App.scss'

import MainHeader from '@/components/MainHeader'
import Login from '@/components/Login'
import AuthTokenContext, { AuthTokenProvider } from '@/contexts/AuthTokenContext'

import { NotificationContextProvider } from './contexts/NotificationContext'
import { FeaturesProvider } from './contexts/FeaturesContext'
import Loading from './components/Loading'
import { PermissionsProvider } from './contexts/PermissionsContext'
import ErrorPage from './components/ErrorPage'

const Issues = React.lazy(() => import('@/components/issues'))
const Messages = React.lazy(() => import('@/components/messages'))
const Home = React.lazy(() => import('@/components/Home'))
const Settings = React.lazy(() => import('@/components/settings'))

const AuthenticatedRoutes = () => {
  const history = useHistory()
  const location = useLocation()
  const [token] = useContext(AuthTokenContext)
  if (!token) {
    // Don't add the redirect path to the url if it's just '/' anyway
    if (history.location.pathname === '/') {
      history.replace('/login')
      return null
    }
    if (history.location.pathname !== '/login') {
      history.replace(`/login?redirect="${encodeURIComponent(history.location.pathname)}"`)
    }
    return null
  }

  return <FeaturesProvider>
    <PermissionsProvider>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect
            from="/:url*\//"
            to={location.pathname.slice(0, -1)}
          />
          <Route path="/issues">
            <Issues />
          </Route>
          <Route path="/messages">
            <Messages />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/NotFound">
            <ErrorPage />
          </Route>
          <Route path="/ServiceUnavailable">
            <ErrorPage />
          </Route>
          <Route path="*">
            <Redirect to="/NotFound"/>
          </Route>
        </Switch>
      </Suspense>
    </PermissionsProvider>
  </FeaturesProvider>
}

const App = () => {
  return <NotificationContextProvider>
    <AuthTokenProvider>
      <Router>
        <MainHeader />
        <div className="App">
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/error">
              <ErrorPage />
            </Route>
            <Route path="/">
              <AuthenticatedRoutes />
            </Route>
          </Switch>
        </div>
        {process.env.REACT_APP_VERSION && <small className="centered margin">
          <em>
              v{process.env.REACT_APP_VERSION}
          </em>
        </small>}
      </Router>
    </AuthTokenProvider>
  </NotificationContextProvider>
}

export default App
