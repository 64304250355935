import { parseReasons, isNumber, isString, isArrayOfType } from '../util'

export const whyIsNotPermission = (thing: any): string | undefined => {
  if (typeof thing !== 'object') {
    return parseReasons(['because it is not an object'])
  }

  const reasons: string[] = []

  if (!isNumber(thing.permissionId)) {
    reasons.push('because property \'permissionId\' is not a number')
  }

  if (!isString(thing.permissionDescription)) {
    reasons.push('because property \'permissionDescription\' is not a string')
  }

  if (thing.partyIds !== null && !isArrayOfType(thing.partyIds, isNumber)) {
    reasons.push('because property \'partyIds\' is not null or a number array')
  }

  return parseReasons(reasons)
}

export const isPermission = (thing: any): thing is Echia.Permission => {
  return !whyIsNotPermission(thing)
}
