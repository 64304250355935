import { useEffect, useState, useCallback, useRef } from 'react'
import Axios, { AxiosRequestConfig } from 'axios'

import useAxios from '@/hooks/useAxios'
import useAddNotification from '@/hooks/useAddNotification'

export default function useData<T> (
  // If null is passed, no request will be made
  url: string | null,
  name: string,
  isT: (thing: any) => thing is T,
  whyIsNotT: (thing: any) => string | undefined,
  useNotifications = true,
  axiosConfig?: AxiosRequestConfig,
): [
  T | undefined,
  boolean,
  () => void,
  number | undefined
] {
  const axios = useAxios(useNotifications)
  const addNotification = useAddNotification()

  const [data, setData] = useState<T | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalResults, setTotalResults] = useState<number>()

  const cancelTokenSourceRef = useRef(Axios.CancelToken.source())

  const updateData = useCallback(() => {
    if (!url) return
    setIsLoading(true)
    cancelTokenSourceRef.current.cancel()
    const thisCancelToken = Axios.CancelToken.source()
    cancelTokenSourceRef.current = thisCancelToken

    let isCancelled = false

    return axios.get(url, {
      ...axiosConfig,
      cancelToken: thisCancelToken.token,
    })
      .then(response => {
        const xTotalResults = Number.parseInt(
          response.headers['x-total-results']
          ?? ''
        )
        if (!Number.isNaN(xTotalResults)) {
          setTotalResults(xTotalResults)
        }
        console.log(response.data)
        return response.data
      })
      .then(data => {
        if (!isT(data)) {
          if (useNotifications) {
            addNotification({
              content: `Got invalid ${name} from API ${whyIsNotT(data)}`,
              type: 'error',
            })
          }
          return
        }
        setData(data)
      })
      .catch(error => {
        if (Axios.isCancel(error)) {
          isCancelled = true
          return
        }
        if (useNotifications && !Axios.isAxiosError(error)) {
          addNotification({
            content: error.message,
            type: 'error',
          })
        }
      })
      .finally(() => {
        if (isCancelled) return
        setIsLoading(false)
      })
  }, [
    cancelTokenSourceRef,
    addNotification,
    axios,
    axiosConfig,
    isT,
    name,
    setData,
    url,
    useNotifications,
    whyIsNotT,
  ])

  useEffect(() => {
    // I can't return this, becuase useEffect only takes return types void or
    // undefined.
    updateData()
  }, [updateData])

  return [data, isLoading, updateData, totalResults]
}
