import React, { createContext, PropsWithChildren } from 'react'

import useData from '@/hooks/useData'
import { makeIsArrayOfType, makeWhyIsNotArrayOfType } from '@/common/typeGuards/util'
import { isPermission, whyIsNotPermission } from '@/common/typeGuards/echia/permission'

type ContextType = [
  Echia.Permission[] | undefined,
  boolean,
  () => void,
  number | undefined
]

const defaultValue: ContextType = [
  [],
  true,
  () => { /* No-op */ },
  undefined,
]

export const PermissionsContext = createContext<ContextType>(defaultValue)

const isPermissionArray = makeIsArrayOfType<Echia.Permission>(isPermission)
const whyIsNotPermissionArray = makeWhyIsNotArrayOfType(whyIsNotPermission)

export const PermissionsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const value = useData<Echia.Permission[]>('/user/permissions',
    'Echia Permission',
    isPermissionArray,
    whyIsNotPermissionArray)
  return <PermissionsContext.Provider value={value} >
    {children}
  </PermissionsContext.Provider>
}
