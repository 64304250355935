import { useMemo } from 'react'

import { makeIsArrayOfType, makeWhyIsNotArrayOfType } from '@/common/typeGuards/util'
import { isMessageFeature, whyIsNotMessageFeature } from '@/common/typeGuards/echia'

import useData from './useData'

export default () => {
  const isMessageFeatureArray = useMemo(
    () => makeIsArrayOfType(isMessageFeature),
    []
  )
  const whyIsNotMessageFeatureArray = useMemo(
    () => makeWhyIsNotArrayOfType(whyIsNotMessageFeature),
    []
  )

  return useData<Echia.MessageFeature[]>(
    '/messageFeatures',
    'Echia MessageFeature array',
    isMessageFeatureArray,
    whyIsNotMessageFeatureArray,
  )
}
