import {
  parseReasons,
  isBoolean,
  isNumber,
  isString,
} from '../util'

export const whyIsNotMessageFeature = (thing: any): string | undefined => {
  if (typeof thing !== 'object') {
    return parseReasons(['because it is not an object'])
  }

  const reasons: string[] = []

  if (!isNumber(thing.clientId)) {
    reasons.push('because property \'clientId\' is not a number')
  }

  if (!isNumber(thing.packageId)) {
    reasons.push('because property \'packageId\' is not a number')
  }

  if (!isNumber(thing.messageTypeId)) {
    reasons.push('because property \'messageTypeId\' is not a number')
  }

  if (!isString(thing.messageType)) {
    reasons.push('because property \'messageType\' is not a string')
  }

  if (!isString(thing.package)) {
    reasons.push('because property \'package\' is not a string')
  }

  if (!isBoolean(thing.messageTypeIsIncoming)) {
    reasons.push('because property \'messageTypeIsIncoming\' is not a string')
  }

  return parseReasons(reasons)
}

export const isMessageFeature = (thing: any): thing is Echia.MessageFeature => {
  return !whyIsNotMessageFeature(thing)
}
