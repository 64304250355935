import React, { createContext, PropsWithChildren, useState } from 'react'

import Cookies from 'universal-cookie'

type ContextType = [
  string | undefined,
  (newToken?: string) => void
]

const defaultValue: ContextType = [
  undefined,
  () => { throw new Error('Not implemented.') },
]

const AuthTokenContext = createContext<ContextType>(defaultValue)

const cookies = new Cookies()
const cookieName = 'token_echia'

const AuthTokenProvider = ({ children }: PropsWithChildren<unknown>) => {
  const cookieToken = cookies.get(cookieName) as string | undefined
  if (cookieToken && typeof cookieToken !== 'string') {
    cookies.remove(cookieName)
    throw new Error(`Invalid format of cookie: ${cookieName}`)
  }

  const [token, setToken] = useState<string | undefined>(cookieToken)

  const updateTokenAndCookie = (newToken?: string) => {
    if (newToken) {
      cookies.set(cookieName, newToken, {
        path: '/',
      })
    } else {
      cookies.remove(cookieName)
    }

    setToken(newToken)
  }

  const providerValue: ContextType = [token, updateTokenAndCookie]
  return <AuthTokenContext.Provider value={providerValue}>
    { children }
  </AuthTokenContext.Provider>
}

export { AuthTokenProvider }
export default AuthTokenContext
