import { useMemo } from 'react'

import { makeIsArrayOfType, makeWhyIsNotArrayOfType } from '@/common/typeGuards/util'
import { isOtherFeature, whyIsNotOtherFeature } from '@/common/typeGuards/echia'

import useData from './useData'

export default () => {
  const isOtherFeatureArray = useMemo(
    () => makeIsArrayOfType(isOtherFeature),
    []
  )
  const whyIsNotOtherFeatureArray = useMemo(
    () => makeWhyIsNotArrayOfType(whyIsNotOtherFeature),
    []
  )

  return useData<Echia.OtherFeature[]>(
    '/otherFeatures',
    'Echia OtherFeature array',
    isOtherFeatureArray,
    whyIsNotOtherFeatureArray,
  )
}
