/**
 * Convert an array of reasons into an explanation string.
 * @param reasons The array of 'because' reasons to be
 * @returns The strings in the reasons array wrapped in ()s and, if there is
 * more than one, joined with ' and 's.
 */
const parseReasons = (reasons: string[]): string | undefined => {
  if (!reasons.length) return undefined

  return '(' + reasons.join(' and ') + ')'
}

export type ReasonIndexes = { [reason: string]: number[] }

const makeReasonsFromReasonIndexes = (
  reasonIndexes: ReasonIndexes
): string[] => {
  return Object.entries(reasonIndexes)
    .map(([reason, indexes]) => {
      const singular = indexes.length === 1
      return `because at index${singular ? '' : 'es'} ${indexes.join(', ')} `
        + 'the element is not of the required type '
        + reason
    })
}

const whyIsNotArrayOfType = (
  thing: any, whyIsNotT: (thing: any) => string | undefined,
): string | undefined => {
  if (!Array.isArray(thing)) {
    return parseReasons(['because it is not an array'])
  }

  const reasonIndexes: ReasonIndexes = {}
  thing.forEach((element, index) => {
    const reason = whyIsNotT(element)
    if (!reason) return

    reasonIndexes[reason] = [...(reasonIndexes[reason] || []), index]
  })

  const reasons = makeReasonsFromReasonIndexes(reasonIndexes)

  return parseReasons(reasons)
}

/**
 * Assert that a given value is an array of a given type.
 * @template T The type to assert that the value is an array of.
 * @param thing The value to check if is an array of the given type.
 * @param isT The typeguard that asserts something is of type T.
 */
const isArrayOfType = <T>(
  thing: any, isT: (thing: any) => thing is T,
): thing is T[] => {
  return Array.isArray(thing) && thing.every(isT)
}

/**
 * Create an array typeguard function for a particular element type. This is
 * useful when you need to pass a typeguard function as a paramater for another
 * function to use.
 * @template T The types that you wish the elements in the array to be.
 * @param isT A typeguard function that asserts that the value it is given is
 * the value that you wish the elements in the array to be.
 * @returns A typeguard function that asserts that the value given to it is an
 * array of the type given to this function.
 */
const makeIsArrayOfType = <T>(
  isT: (thing: any) => thing is T,
): (thing: any) => thing is T[] => {
  return (thing: any): thing is T[] => isArrayOfType<T>(thing, isT)
}

/**
 * Create an array typeguard-failure-reasons function for a particular element
 * type. This is useful when you need to pass the typeguard-failure-reasons
 * function as a paramater for another function to use.
 * @param whyIsNotT A typeguard-failure-reasons function that gives reasons why
 * an object passed to it is not of the type that you want elements of the array
 * to be.
 * @returns A typeguard-failure-reasons function that gives reasons that the
 * value given to it is not an array with elements of the desired type.
 */
const makeWhyIsNotArrayOfType = (
  whyIsNotT: (thing: any) => string | undefined,
) => {
  return (thing: any): string | undefined => {
    return whyIsNotArrayOfType(thing, whyIsNotT)
  }
}

const whyIsNotString = (thing: any): string | undefined => {
  if (typeof thing === 'string') return undefined
  return parseReasons(['because does not have a typeof \'string\''])
}

const isString = (thing: any): thing is string => !whyIsNotString(thing)

const isStringArray = (a: any): a is string[] => {
  return isArrayOfType<string>(a, isString)
}

const whyIsNotNumber = (thing: any): string | undefined => {
  if (typeof thing === 'number') return undefined
  return parseReasons(['because it does not have a typeof \'number\''])
}

const isNumber = (thing: any): thing is number => !whyIsNotNumber(thing)

const isNumberArray = (a: any): a is number[] => {
  return isArrayOfType<number>(a, isNumber)
}

const whyIsNotNonEmptyString = (thing: any): string | undefined => {
  if (typeof thing === 'string' && thing !== '') return undefined
  return parseReasons([
    'because it either does not have a typeof \'string\' or it does not '
    + 'equal the empty string',
  ])
}

const isNonEmptyString = (thing: any): thing is string => {
  return !whyIsNotNonEmptyString(thing)
}

const whyIsNotBoolean = (thing: any): string | undefined => {
  if (typeof thing === 'boolean') return undefined
  return parseReasons(['because it does not have a typeof \'boolean\''])
}

const isBoolean = (thing: any): thing is boolean => !whyIsNotBoolean(thing)

const whyIsNotDateString = (thing: any): string | undefined => {
  if (typeof thing !== 'string' || Number.isNaN(new Date(thing).getTime())) {
    return parseReasons(['because it is not a valid date string'])
  }

  return undefined
}

const isDateString = (thing: any): thing is string => !whyIsNotDateString(thing)

const whyIsNotStringKeyedEnum = (someEnum: { [key: string]: any }, thing: any): string | undefined => {
  if (!Object.values(someEnum).includes(thing)) {
    return parseReasons([
      `because value ${thing} is not incuded included in possible values (${
        Object.values(someEnum).join(', ')
      })`,
    ])
  }
  return parseReasons([])
}

const isStringKeyedEnum = <T extends { [key: string]: any }>(someEnum: T, thing: any): thing is T[keyof T] => {
  return !whyIsNotStringKeyedEnum(someEnum, thing)
}

const whyIsNotStringToStringRecord = (thing: any): string | undefined => {
  if (typeof thing !== 'object') {
    return parseReasons(['because it is not an object'])
  }

  const reasons: string[] = []

  Object.entries(thing).forEach(([key, value]) => {
    if (!isString(value)) {
      reasons.push(`because the value for '${key}' is not a string`)
    }
  })

  return parseReasons(reasons)
}

const isStringToStringRecord = (
  thing: any
): thing is Record<string, string> =>
  !whyIsNotStringToStringRecord(thing)

export {
  parseReasons,
  makeReasonsFromReasonIndexes,
  whyIsNotArrayOfType,
  isArrayOfType,
  whyIsNotString,
  isStringArray,
  isString,
  isDateString,
  whyIsNotDateString,
  whyIsNotNumber,
  isNumber,
  isNumberArray,
  whyIsNotNonEmptyString,
  isNonEmptyString,
  whyIsNotBoolean,
  isBoolean,
  makeIsArrayOfType,
  makeWhyIsNotArrayOfType,
  isStringKeyedEnum,
  whyIsNotStringKeyedEnum,
  isStringToStringRecord,
  whyIsNotStringToStringRecord,
}
