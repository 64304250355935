import { useContext, useRef } from 'react'
import axios, { AxiosInstance } from 'axios'

import { useHistory } from 'react-router-dom'

import AuthTokenContext from '@/contexts/AuthTokenContext'
import config from '@/config'

import useAddNotification from './useAddNotification'

const useAxios = (useNotifications = true) => {
  const axiosRef = useRef<AxiosInstance | undefined>()
  const [token, setToken] = useContext(AuthTokenContext)
  const addNotification = useAddNotification()
  const history = useHistory()

  if (axiosRef.current) return axiosRef.current

  // Put any settings you want to use an all default config network requests here
  const axiosSettings = {
    baseURL: config.apiBaseUrl,
    timeout: config.defaultNetworkRequestTimeout ?? 0,
    headers: {
      Authorization: token && `bearer ${token}`,
      'Content-Type': 'application/json',
      'x-migration': config.migration,
      'x-version': config.version,
    },
  }

  axiosRef.current = axios.create(axiosSettings)

  // Add an interceptor. This will read the response before it handled by what
  // calls it. We will use this to log the user out when they are not
  // authorised.
  axiosRef.current.interceptors.response.use(
    // 2XX responses function
    response => response,
    // Non-2XX responses
    error => {
      if (axios.isCancel(error)) return Promise.reject(error)
      // If 401 response, log the user out by removing the token.
      if (error.response && error.response.status === 401) {
        setToken()
      }
      if (
        error.response
        && error.response.status === 503
        && !history.location.pathname.startsWith('/ServiceUnavailable')) {
        history.push(`/ServiceUnavailable?redirect="${
          encodeURIComponent(history.location.pathname)
        }"`)
      }
      if (useNotifications) {
        let content = error.message
        if (error.response) {
          content = `Error ${error.response.status}: ${error.response.data}`
        }
        addNotification({ content, type: 'error' })
      }
      return Promise.reject(error)
    },
  )

  return axiosRef.current
}

export default useAxios
