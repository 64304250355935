import { parseReasons, isNumber, isString } from '../util'

export const whyIsNotOtherFeature = (thing: any): string | undefined => {
  if (typeof thing !== 'object') {
    return parseReasons(['because it is not an object'])
  }

  const reasons: string[] = []

  if (!isNumber(thing.otherFeatureId)) {
    reasons.push('because property \'otherFeatureId\' is not a number')
  }

  if (!isNumber(thing.packageId)) {
    reasons.push('because property \'packageId\' is not a number')
  }

  if (!isString(thing.description)) {
    reasons.push('because property \'description\' is not a string')
  }

  if (!isString(thing.package)) {
    reasons.push('because property \'package\' is not a string')
  }

  return parseReasons(reasons)
}

export const isOtherFeature = (thing: any): thing is Echia.OtherFeature => {
  return !whyIsNotOtherFeature(thing)
}
