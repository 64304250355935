import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import * as zod from 'zod'

import config from '@/config'

import AuthTokenContext from '@/contexts/AuthTokenContext'
import useQueryParams from '@/hooks/useQueryParams'

import Button from '@/components/Button'
import Loading from '@/components/Loading'

import './Login.scss'

const Login = () => {
  const history = useHistory()
  const [params] = useQueryParams({
    redirect: zod.string(),
  })

  const [clientName, setClientName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [token, setToken] = useContext(AuthTokenContext)

  const redirectUrl = params.redirect
    ? decodeURIComponent(params.redirect)
    : '/'

  // Redirect if already logged in.
  if (token) {
    history.replace(redirectUrl)
  }

  const attemptLogin = (
    clientName: string,
    username: string,
    password: string,
  ) => {
    setIsLoading(true)
    setMessage('')
    axios.post(config.authUrl, {
      clientName,
      username,
      password,
    })
      .then(response => {
        setToken(response.data)
        history.replace(redirectUrl)
      })
      .catch(error => {
        console.error(error)
        setMessage(error.response?.data || error.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return <div className="Login">
    <div className="container">
      <form
        onSubmit={event => {
          event.preventDefault()
          if (isLoading) return

          attemptLogin(clientName, username, password)
        }}
      >
        <div>
          <h3>Login</h3>
          <label htmlFor="clientName">
            Client Name
          </label>
          <input
            type="text"
            value={clientName}
            onChange={event => setClientName(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="username">
            Username
          </label>
          <input
            type="text"
            value={username}
            onChange={event => setUsername(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="password">
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
        </div>

        { isLoading && <Loading /> }
        { message && <div className="centered errorText">
          { message }
        </div> }

        <div className="submit">
          <Button type="submit" disabled={isLoading}>
            Login
          </Button>
        </div>
      </form>
    </div>
  </div>
}

export default Login
