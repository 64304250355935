import React from 'react'
import classnames from 'classnames'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

import './NotificationRow.scss'

interface Props {
  notification: Dashboard.Notification
  removeNotification: () => void
}

export default ({ notification, removeNotification }: Props) => {
  return <div className={classnames('NotificationRow', notification.type)}>
    <pre>
      {notification.content}
    </pre>
    <div className='dismiss' onClick={removeNotification}><Icon path={mdiClose} size={1} /></div>
  </div>
}
