import React, { useContext, PropsWithChildren } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import AuthTokenContext from '@/contexts/AuthTokenContext'
import './MainHeader.scss'

const NavLink = ({
  children,
  link,
}: PropsWithChildren<{ link: string }>) => {
  const location = useLocation()
  const active = link === '/' ? location.pathname === '/' : location.pathname.startsWith(link)
  return <Link to={link} className={classnames('NavLink', { active })}>
    <span>{children}</span>
  </Link>
}

const NavClick = ({ children, onClick }: PropsWithChildren<{
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}>) => {
  return <span className="NavClick" onClick={onClick}>
    <span>{ children }</span>
  </span>
}

const MainHeader = () => {
  const [token, setToken] = useContext(AuthTokenContext)
  const history = useHistory()

  const logout = () => {
    setToken()
    history.replace('/login')
  }

  return <div className="MainHeader">
    <div className="spacer" />
    <header>
      <h1 onClick={() => history.replace('/')} style={{ fontWeight: 400 }}>
        echia <small style={{ fontSize: '1rem' }}>By Envisage Software</small>
      </h1>
      { token && <nav>
        {/* <NavLink link="/" >
          Home
        </NavLink> */}
        <NavLink link="/messages">
          Messages
        </NavLink>
        <NavLink link="/issues">
          Issues
        </NavLink>
        <NavLink link="/settings">
          Settings
        </NavLink>
        <NavClick onClick={logout}>
          Logout
        </NavClick>
      </nav> }
    </header>
  </div>
}

export default MainHeader
