import React from 'react'
import classnames from 'classnames'
import './Button.scss'
import Icon from '@mdi/react'

// These are the types for a 'div'
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
{
  color?: 'blue' | 'green' | 'red'
  inline?: boolean
  icon?: string
  iconColor?: string
  iconHorizontal?: boolean
  iconVertical?: boolean
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

// Note the behaviour of pass
const Button = ({
  children,
  color,
  inline,
  icon,
  iconHorizontal,
  iconVertical,
  iconColor,
  type,
  disabled,
  ...implicitProps
}: Props) => {
  if (inline) {
    return <span className="InlineButtonWrapper">
      <button
        type="button"
        {...implicitProps}
        className={classnames('Button', implicitProps.className, { [String(color)]: !!color })}
        disabled={disabled}
      >
        {icon && <>
          <Icon
            path={icon}
            size='1.2rem'
            color={disabled
              ? '#aaa'
              : (iconColor || (color ? 'white' : '#333'))}
            horizontal={iconHorizontal}
            vertical={iconVertical}
          />
        </>}
        <span className="buttonContent">
          {icon && <>&nbsp;</>}
          {children}
        </span>
      </button>
    </span>
  }
  return <button
    type={type || 'button'}
    {...implicitProps}
    disabled={disabled}
    className={classnames('Button', implicitProps.className, { [String(color)]: !!color })}
  >
    {icon && <>
      <Icon
        path={icon}
        size='1.25rem'
        color={disabled
          ? '#aaa'
          : (iconColor || (color ? 'white' : '#333'))}
        horizontal={iconHorizontal}
        vertical={iconVertical}
      />
    </>}
    <span className="buttonContent">
      {icon && <>&nbsp;</>}
      {children}
    </span>
  </button>
}

export default Button
