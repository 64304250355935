import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'

import NotificationRow from './NotificationRow'

import './NotificationPanel.scss'

interface Props {
  notifications: Dashboard.Notification[]
  removeNotification: (index: number) => void
  removeAllNotifications: () => void
}

export default ({ notifications, removeNotification, removeAllNotifications }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  useEffect(() => {
    setIsExpanded(true)
  }, [notifications.length])
  if (notifications.length === 0) return null
  return <div className="NotificationPanel">
    <div
      className="header"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span className="leftPartOfHeader">
        <Icon path={mdiChevronDown} vertical={!isExpanded} size={1} style={{
          position: 'relative',
          top: '5px',
        }} />
        Notifications <span style={{ fontSize: '0.9em' }}>- {notifications.length}</span>
      </span>
      <span className="closeAll" onClick={event => {
        event.stopPropagation()
        removeAllNotifications()
      }}>CLEAR ALL</span>
    </div>
    <div className={'notificationList' + (isExpanded ? ' expanded' : '')}>
      <div>
        {notifications.map((notification, index) => <NotificationRow
          key={index}
          notification={notification}
          removeNotification={() => removeNotification(index)}
        />)}
      </div>
    </div>
  </div>
}
