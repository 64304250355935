import { useMemo, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import * as zod from 'zod'

/**
 *
 * @param schema A Zod Record Schema defining the parameters to query.
 */
const useQueryParams = <Shape extends zod.ZodRawShape, Params = zod.infer<zod.ZodObject<Shape>>>(shape: Shape): [
  Partial<Params>,
  (newParams: Partial<Params>) => void
] => {
  const { search } = useLocation()
  const history = useHistory()

  const params = useMemo((): Partial<Params> => {
    const schema = zod.object(shape)
    const parsed = Object.fromEntries(Object.entries(qs.parse(
      search.startsWith('?') ? search.substring(1) : search,
      { comma: true }
    )).map(([key, value]) => [key, typeof value === 'string' ? JSON.parse(value) : value]))

    const result = schema.partial().passthrough().safeParse(parsed)
    if (result.success) return result.data as any
    // If it failed to parse, delete failing elements and retry
    result.error.issues?.forEach(issue => {
      delete parsed[issue.path[0]]
    })
    return schema.partial().passthrough().parse(parsed) as any // TODO: Fix this type
  }, [search, shape])

  // We parse serialize and deserialize so that we can memoize the deserialize to only occur if the serialized value changes
  // This allows us to keep the output params referentially stable, even with complex values e.g. Arrays
  const serializedParams = JSON.stringify(params)
  const memoizedParams: Partial<Params> = useMemo(() => JSON.parse(serializedParams), [serializedParams])

  const setParams = useCallback((newParams: Partial<Params>) => {
    const jsonParams = Object.fromEntries(Object.entries({
      ...memoizedParams,
      ...newParams,
    }).map(([key, value]) => [key, JSON.stringify(value)]))

    history.replace({
      search: '?' + qs.stringify(jsonParams),
    })
  }, [history, memoizedParams])

  // TODO: Don't return all params
  return [
    memoizedParams,
    setParams,
  ]
}

export default useQueryParams
